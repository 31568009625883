// dependencies.
import React from 'react'
// components.
import FadeCarousel from 'components/carousel/FadeCarousel'

// Styles & Images
import 'src/components/pages/web3-wallet/scss/CarouselSection.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

// Main component:
const CarouselSection = ({
  h2,
  h3,
  items,
  style,
  visibleItemsCount,
  backgroundImage,
  backgroundOffset,
  backgroundStyle,
  shadow,
}) => {
  if (!items.length) return null

  return (
    <section className="x__web3-wallet-page__carousel-section" style={style}>
      {!!backgroundImage && (
        <GatsbyImage
          className="x__web3-wallet-page__carousel-section__background"
          image={backgroundImage}
          alt="background"
          loading="lazy"
          style={backgroundStyle}
        />
      )}
      {(!!h2 || !!h3) && (
        <div className="x__web3-wallet-page__carousel-section__content">
          {!!h2 && <h2 className="x__web3-wallet-page__carousel-section__heading">{h2}</h2>}
          {!!h3 && (
            <h3
              className="x__web3-wallet-page__carousel-section__subheading"
              dangerouslySetInnerHTML={{ __html: h3 }}
            />
          )}
        </div>
      )}

      <FadeCarousel shadow={shadow} items={items} visibleItemsCount={visibleItemsCount} />
    </section>
  )
}

export default CarouselSection
