// dependencies.
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
// components.
import PageHead from 'src/components/head/PageHead'
import { Header, Footer } from 'src/components/layout'
import { CarouselSection } from 'components/pages/web3-wallet'
import { AssetsSection, HeroSection } from 'src/components/pages/shared'
// utils.
import { useIsMobileDevice } from 'js/utils/hooks'
// data.
import data from 'src/data/pages/Contents/web3-wallet.json'
// constants.
import { OS_KEYS } from 'src/components/button/download-v2/constants'

// Styles & Images
import 'static/web3-wallet/scss/styles.scss'

// Main Component:
const BrowserExtension = ({ pageName = 'web3-wallet', pageData = data, location }) => {
  const { content } = pageData
  const { header, extension, mobile, features, assets } = content

  // get images.
  const images = useStaticQuery(graphql`
    query {
      bgMobile: file(relativePath: { regex: "/^web3-wallet\/img\/header-bg-m\\.jpg$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 760, placeholder: BLURRED)
        }
      }
      bgDesktop: file(relativePath: { regex: "/^web3-wallet\/img\/header-bg-d\\.jpg$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 3200, placeholder: BLURRED)
        }
      }

      glowDesktop1: file(relativePath: { regex: "/^web3-wallet\/img\/glow-1-d\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
        }
      }

      glowDesktop2: file(relativePath: { regex: "/^web3-wallet\/img\/glow-2-d\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
        }
      }

      glowDesktop3: file(relativePath: { regex: "/^web3-wallet\/img\/glow-3-d\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
        }
      }

      glowMobile1: file(relativePath: { regex: "/^web3-wallet\/img\/glow-1-m\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 768, placeholder: BLURRED)
        }
      }

      glowMobile2: file(relativePath: { regex: "/^web3-wallet\/img\/glow-2-m\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 768, placeholder: BLURRED)
        }
      }

      glowMobile3: file(relativePath: { regex: "/^web3-wallet\/img\/glow-3-m\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 768, placeholder: BLURRED)
        }
      }

      featuresSlide1: file(relativePath: { regex: "/^web3-wallet\/img\/features\/slide_1\\.webp$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }
      featuresSlide2: file(relativePath: { regex: "/^web3-wallet\/img\/features\/slide_2\\.webp$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }
      featuresSlide3: file(relativePath: { regex: "/^web3-wallet\/img\/features\/slide_3\\.webp$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }

      mobileSlide1: file(relativePath: { regex: "/^web3-wallet\/img\/mobile\/slide_1\\.webp$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }
      mobileSlide2: file(relativePath: { regex: "/^web3-wallet\/img\/mobile\/slide_2\\.webp$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }
      mobileSlide3: file(relativePath: { regex: "/^web3-wallet\/img\/mobile\/slide_3\\.webp$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }

      showcaseSlide1: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      showcaseSlide2: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      showcaseSlide3: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      showcaseSlide4: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_4\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
    }
  `)

  // carousel images.
  const carouselImageMap = {
    extension: [
      getImage(images.featuresSlide1),
      getImage(images.featuresSlide2),
      getImage(images.featuresSlide3),
    ],
    mobile: [
      getImage(images.mobileSlide1),
      getImage(images.mobileSlide2),
      getImage(images.mobileSlide3),
    ],
    features: [
      getImage(images.showcaseSlide1),
      getImage(images.showcaseSlide2),
      getImage(images.showcaseSlide3),
      getImage(images.showcaseSlide4),
    ],
  }

  const getItemsWithImages = (items, key) =>
    items.map((item, i) => ({ ...item, image: carouselImageMap[key][i] }))

  const { isMobile: isMobileLayout } = useIsMobileDevice()
  const carouselStyles = isMobileLayout
    ? {
        extension: {
          order: 2,
          // Used to make glow background to be under the carousel
          zIndex: 2,
        },
        mobile: {
          order: 1,
          zIndex: 3,
        },
        features: {
          order: 3,
        },
      }
    : {
        extension: {
          order: 1,
        },
        mobile: {
          order: 2,
        },
        features: {
          order: 3,
        },
      }

  const downloadButtonProps = {
    forceOS: isMobileLayout ? undefined : OS_KEYS.web3,
    style: {
      background: `linear-gradient(192deg, #48E6B4 -44%, #5587EC 108%)`,
      boxShadow: `0px 10px 30px 0px rgba(27, 109, 105, 0.50)`,
    },
  }

  return (
    <div className="x__web3-wallet-page x-page-browser-extension">
      <Header trackerPage={pageName} location={location} />

      <main className="x">
        <HeroSection
          location={location}
          pageName={pageName}
          downloadButtonProps={downloadButtonProps}
          data={{
            h1: header.h1,
            h2: header.h2,
          }}
          backgroundImage={isMobileLayout ? getImage(images.bgMobile) : getImage(images.bgDesktop)}
        />
        <div className="x__web3-wallet-page__carousels-wrapper">
          <CarouselSection
            style={carouselStyles.extension}
            h2={isMobileLayout ? extension.h2 : undefined}
            h3={isMobileLayout ? extension.h3 : undefined}
            backgroundImage={isMobileLayout ? getImage(images.glowMobile1) : null}
            backgroundStyle={isMobileLayout ? { transform: 'translateY(-37%)' } : undefined}
            items={getItemsWithImages(extension.items, 'extension')}
          />

          <CarouselSection
            style={carouselStyles.mobile}
            backgroundImage={isMobileLayout ? undefined : getImage(images.glowDesktop1)}
            backgroundStyle={isMobileLayout ? undefined : { transform: 'translateY(-12%)' }}
            h2={isMobileLayout ? undefined : mobile.h2}
            h3={isMobileLayout ? undefined : mobile.h3}
            items={getItemsWithImages(mobile.items, 'mobile')}
          />

          <CarouselSection
            visibleItemsCount={1}
            style={carouselStyles.features}
            backgroundImage={
              isMobileLayout ? getImage(images.glowMobile2) : getImage(images.glowDesktop2)
            }
            backgroundStyle={{ transform: `translateY(${isMobileLayout ? '5%' : '18%'})` }}
            h2={features.h2}
            h3={features.h3}
            shadow={false}
            items={getItemsWithImages(features.items, 'features')}
          />
        </div>
        {!!assets && (
          <AssetsSection
            id="assets"
            data={{
              ...assets,
              backgroundImage: isMobileLayout
                ? getImage(images.glowMobile3)
                : getImage(images.glowDesktop3),
            }}
            backgroundStyle={{ transform: `translateY(-42%)` }}
          />
        )}

        <Footer enableSubscribeNotice={false} />
      </main>
    </div>
  )
}

export default BrowserExtension

// <head> component:
export const Head = () => <PageHead page="web3-wallet" />
